import axios from 'axios'
import Tingle from '../vendor/tingle'
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

class PageResurcesEdit {

    constructor() {
        this.cont = 1;
        this.services = {
            grado: '/ajax/grades',
            competencia: '/ajax/proficiencies',
            ejeTematico: (gradeId) => `/ajax/thematic-axis/${gradeId}`,
            filter: (gradoId = '', competenciaId = '', ejeId = '') => `/ajax/activities/filter?grado=${gradoId}&competencia=${competenciaId}&eje_tematico=${ejeId}`,
            visualize: (teacherseccionid, actual = '', nuevo = '') => `/ajax/activities/visualize/${teacherseccionid}/${actual}/${nuevo}`,
            update: (teacher, teacherseccionid, actual = '', nuevo = '') => `/ajax/activities/update/${teacher}/${teacherseccionid}/${actual}/${nuevo}`,
        }


        //
        this.$results = document.querySelector('#Results')
        this.$resultsCount = document.querySelector('#ResultsCount') //15 Actividades
        this.$selectGrado = document.querySelector('#SelectGrado')
        this.$selectCompetencia = document.querySelector('#SelectCompetencia')
        this.$selectEje = document.querySelector('#SelectEje')


        //
        this.$updateButton = document.querySelector('#Update')
        //

        this.$view = {
            step: document.querySelector('.lq-results-v'),
            step3: document.querySelector('.lq-table-view'),
            detail: document.querySelector('.lq-detial-v'),
            header: document.querySelector('.m-banner'),
            slider: document.querySelector('.lq-teachers-list__slider'),
        }

        this.init()
        this.activeListenerStep();
    }

    activeStep(number) {
        const steps = document.querySelectorAll('[data-step]')

        steps.forEach(step => {
            step.classList.remove('active')
        })

        document.querySelector(`[data-step="${number}"]`).classList.add('active')
    }

    templateNoResults() {
        return `
            <p class="no-results">No se encontraron resultados.</p>
        `
    }

    templateResult(detail) {
        // const { detail_activities } = detail
        document.getElementById('first-warning').className += " hidden-warning";

        let content = `
            <div class="lq-teachers-list__item">
                <div class="lq-teachers-list__item-activity">Actividad ${this.cont}</div>
                <div class="lq-teachers-list__item-title"><span>${detail.descripcion}</span></div>
                <div class ="order-buttons">
                <div class="lq-teachers-list__item-view">
                    <button
                        type="button"
                        data-id="${detail.id}"
                        class="lq-button js-action-view"
                    >
                        <i class="lq-button__icon lq-button__icon--eye" data-id="${detail.id}"></i> <span data-id="${detail.id}">Ver</span>
                    </button>
                </div>
                <div class="lq-teachers-list__item-select">
                    <span class="select-title">Seleccionar</span>
                    <span class="theselect js-action-select">
                        <label class="m-input-label-check" data-id="${detail.id}">
                        <input name="motivo" type="radio" value="Si"/>
                        <span class="m-input-check" data-id="${detail.id}"></span>
                        </label>
                    </span>
                </div>
                </div>
            </div>
        `

        return content
    }

    noResults() {
        this.$resultsCount.innerHTML = ''
        this.$results.innerHTML = this.templateNoResults()
    }

    results(data = []) {
        this.activeStep(2)

        // Views
        this.$results.classList.remove('d-none')
        this.$view.slider.classList.add('d-none')

        localStorage.setItem('_data', JSON.stringify(data))

        this.$resultsCount.innerHTML = data.length + " Actividades"
        this.$results.innerHTML = ''

        // data.forEach(elem => this.$results.insertAdjacentHTML('beforeend', this.templateResult(elem)))
        data.forEach((elem) => {
            this.$results.insertAdjacentHTML('beforeend', this.templateResult(elem));
            this.cont++;
        });
        this.cont = 1;

        this.listenerActions()
    }

    detailActity(evt) {
        const id = parseInt(evt.target.dataset.id) // ID seleccionado
        const data = JSON.parse(localStorage.getItem('_data'))
        let indexSelec = null
        let bulk = ''


        // Views
        this.$results.classList.add('d-none')
        this.$view.slider.classList.remove('d-none')
        this.$view.slider.innerHTML = ''

        data.forEach((elem, index) => {
            const hasRecommendation = elem?.recommendation;
            indexSelec = elem.id == id ? index : indexSelec
            bulk += `
                <div class="swiper-slide">
                    <div class="lq-teachers-list__slider-item">
                        <div class="lq-teachers-detail__content">
                        <div class="header-detail-activity">
                            <div class="left-div">
                                <div class="lq-teachers-list__slider-count">Actividad ${index + 1} de ${data.length}</div>
                                    <h3 class="lq-teachers-detail__title">${elem.descripcion}</h3>
                            </div>
                            <div class="right-div">

                                <button class="lq-cta-bottom js-action-back-list">
                                   Volver a Lista
                                </button>

                            </div>
                        </div>
                            <hr>
                            <div class="lq-teachers-detail__video">
                                ${elem.url_video}
                                </div>
                            <div class="lq-teachers-detail__video-footer">
                                ${elem.extra_content == null ? '' : elem.extra_content}
                            </div>
                            <div class="lq-teachers-detail__resources">
                            <h4>Recursos para descarga:</h4>
                            <div class="downloaders">
                                <a href="/resources-plantilla/matriz/${elem.matriz}" class="lq-teachers-detail__resources-button" target="_blank">
                                    <img src="/images/icon-idownload.svg"><span>Matriz curricular</span>
                                </a>
                                <a href="/resources-plantilla/solucionario/${elem.solucionario}" class="lq-teachers-detail__resources-button" target="_blank">
                                    <img src="/images/icon-idownload.svg"><span>Actividad con respuestas</span>
                                </a>
                                <a href="/resources-plantilla/cargable/${elem.cargable}" class="lq-teachers-detail__resources-button" target="_blank">
                                    <img src="/images/icon-idownload.svg"><span>Actividad sin respuestas</span>
                                </a>
                                ${hasRecommendation ? `
                                <a href="/recommendation/${elem.recommendation.nombre_archivo}" class="lq-teachers-detail__resources-button" target="_blank">
                                    <img src="/images/icon-idownload.svg"><span>Recomendaciones</span>
                                </a>
                                ` : ''}
                            </div>
                            </div>
                            <div class="lq-teachers-list__slider-cta">
                                <button class="lq-cta-bottom js-action-select" data-id="${elem.id}">
                                    Seleccionar actividad
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            `
        })

        this.$view.slider.innerHTML = `
            <div class="swiper slider-activities">
                <div class="swiper-wrapper" style="height:fit-content;">
                    ${bulk}
                </div>
                <div class="swiper-button-prev">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="28" cy="28" r="27.5" stroke="#FFAD12"/>
                        <path d="M32 37.7275L22 27.7275L32 17.7275" stroke="#FFAD12" stroke-width="3" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="swiper-button-next">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="28" cy="28" r="27.5" transform="rotate(180 28 28)" stroke="#FFAD12"/>
                        <path d="M24 18.2725L34 28.2725L24 38.2725" stroke="#FFAD12" stroke-width="3" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        `

        const swiper = new Swiper('.slider-activities', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })

        if (indexSelec) swiper.slideTo(indexSelec)

        this.listenerActions();
        this.listenerActionsBackList();
        this.listenerActionsNavigationButton()
    }

    modalConfirm(evt) {
        const _self = this
        let elemento=evt.target.parentNode.parentNode;
        this.resetVideo(elemento)
        evt.preventDefault()
        evt.stopPropagation()

        const modal = new Tingle.modal({
            footer: false,
            stickyFooter: false,
            closeMethods: ['overlay', 'escape'],
            cssClass: ['modal-confirm'],
            onOpen: function () {
            },
            onClose: function () {
                console.log('modal closed');
            },
            beforeClose: function () {
                return true; // close the modal
            }
        })

        // Set current activity
        localStorage.setItem('_id', parseInt(evt.target.dataset.id))

        // HTML content modal
        let modalContent = document.createElement('div')
        modalContent.classList.add('modal-confirm__content')

        let modalContentClose = document.createElement('div')
        modalContentClose.innerHTML = `X`
        modalContentClose.classList.add('modal-confirm__close')

        let modalContentTitle = document.createElement('h2')
        modalContentTitle.innerHTML = `¿Estas seguro que deseas<br>marcar esta opción?`

        let modalContentButton = document.createElement('button')
        modalContentButton.classList.add('confirm-js')
        modalContentButton.innerHTML = `Confirmar selección`

        modalContent.appendChild(modalContentClose)
        modalContent.appendChild(modalContentTitle)
        modalContent.appendChild(modalContentButton)

        // Set content modal
        modal.setContent(modalContent)

        // Set listeners
        modalContentButton.addEventListener('click', () => {
            modal.close()

            // Go to view
            _self.showDetail()
        })

        modalContentClose.addEventListener('click', () => modal.close())

        modal.open()
    }

    listenerActionsNavigationButton(){
        const btnNext = document.querySelector('.swiper-button-next');
        const btnPrev = document.querySelector('.swiper-button-prev');

        btnNext.addEventListener('click', () => {
            let elem=document.getElementsByClassName('swiper-slide-prev')[0];
            this.resetVideo(elem);
        });
         btnPrev.addEventListener('click', () => {
            let elem=document.getElementsByClassName('swiper-slide-next')[0];
            this.resetVideo(elem);
         });
    }


    listenerActionsBackList(){
        const _self = this
        const back_list = document.querySelectorAll('.js-action-back-list');
        back_list.forEach(elem =>{
            elem.addEventListener('click',this.backList.bind(_self))
        });
    }

    backList(evt){
        let elemento=evt.target.parentNode.parentNode.parentNode;
        this.resetVideo(elemento)
        this.$results.classList.remove('d-none')
        this.$view.slider.classList.add('d-none')
    }

    resetVideo(nodeParent){
        let elemento=nodeParent.getElementsByTagName("iframe");
        if(elemento.length>0){
            let video=elemento[0];
            let data=elemento[0].parentNode;
            data.removeChild(elemento[0]);
            data.appendChild(video);
        }
    }


    listenerActions() {
        const _self = this

        const views = document.querySelectorAll('.js-action-view')
        const actions = document.querySelectorAll('.js-action-select')

        views.forEach(elem => {
            elem.addEventListener('click', _self.detailActity.bind(this))
        })

        actions.forEach(elem => elem.addEventListener('click',this.modalConfirm.bind(_self) ))

    }

    getDetail(id) {
        return JSON.parse(localStorage.getItem('_data')).find(dt => dt.id === parseInt(id))
    }

    async getDetailVisualize() {
        try {
            const url = this.services.visualize(localStorage.getItem('_teacher_seccion_id'), localStorage.getItem('_current_id'), localStorage.getItem('_id'))
            const { data: { data } } = await axios.get(url)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async showDetail() {
        const data = this.getDetail(localStorage.getItem('_id'))
        this.$view.step.classList.add('d-none')
        this.$view.step3.classList.remove('d-none')

        this.$view.header.scrollIntoView({ behavior: 'smooth' })

        this.activeStep(3)

        // const { detail_activities } = data
        const visualizeData = await this.getDetailVisualize()
        //console.log(visualizeData)
        // Detail fill
        this.$view.step3.querySelector('.r-title').innerHTML = data.descripcion
        this.$view.step3.querySelector('.r-month').innerHTML = visualizeData.currentMonth

        this.$view.step3.querySelector('.lq-table').innerHTML = `
            <div class="lq-table-row lq-table-header lq-teachers-table__header">
                <div class="lq-table-cell-header lq-teachers-table__header-week">
                    <span>Semana</span>
                </div>
                <div class="lq-table-cell-header lq-teachers-table__header-date">
                    <span>Fecha de inicio</span>
                </div>
                <div class="lq-table-cell-header lq-teachers-table__header-activity">
                    <span>Actividad</span>
                </div>
            </div>
        `
        // 4
        visualizeData.activities.forEach((elem, index) => {
            //console.log(elem['fecha_inicio'])
            if (elem['descripcion'] != null) {
                let day = new Date(elem['fecha_inicio']).getDate() + 1

                let month = new Date(elem['fecha_inicio'])
                    .toLocaleString('es-ES', { timeZone: 'America/Lima', month: 'long' })

                this.$view.step3.querySelector('.lq-table').insertAdjacentHTML('beforeend', `
                    <div class="lq-table-row lq-table-mov">
                        <div class="lq-table-cell ${elem['edit'] == 1 ? 'font-weight-bold' : ''} text-center" data-title="Semana">
                            ${index + 1}
                        </div>
                        <div class="lq-table-cell ${elem['edit'] == 1 ? 'font-weight-bold' : ''}" data-title="Fecha de inicio">
                            ${day} de ${month}
                        </div>
                        <div class="lq-table-cell ${elem['edit'] == 1 ? 'font-weight-bold' : ''}" data-title="Actividad">
                            ${elem['descripcion'] == null ? "-" : elem['descripcion']}
                        </div>
                    </div>

                `)
                this.$view.step3.querySelector('.lq-table').insertAdjacentHTML('beforeend', `
                    <div class="mobile mb-table_row-edit">
                        <div class="mb-table-cell-firts b-none"
                        data-title="Semana"><strong>Semana ${index + 1}</strong>
                            <br> ${day} de ${month} </div>
                        <div class="mb-table-cell b-none ${elem['edit'] == 1 ? 'font-weight-bold' : ''}" data-title="Actividad">
                            ${elem['descripcion'] == null ? "-" : elem['descripcion']}
                        </div>
                    </div>
                `)

                if(elem['edit'] == 1){
                    const saveButtons = document.querySelectorAll('.saveClick');

                    saveButtons.forEach(button => {
                        button.setAttribute('data-activity', elem['actividad_chile_id']);
                    });
                }
            }
        })
    }

    async handleResuls() {
        const valueGrado = this.$selectGrado.options[this.$selectGrado.selectedIndex].value
        const valueCompetencia = this.$selectCompetencia.options[this.$selectCompetencia.selectedIndex].value
        const valueEje = this.$selectEje.options[this.$selectEje.selectedIndex].value

        try {
            const { data: { data } } = await axios.get(this.services.filter(valueGrado, valueCompetencia, valueEje))
            // no results
            if (data.length < 1) {
                this.noResults()
                localStorage.setItem('_data', '')
                return
            }

            // results
            this.results(data)
        } catch (error) {
            console.error(error)
        }
    }

    async filterGrade() {
        try {
            const { data: { data } } = await axios.get(this.services.grado)

            data.forEach(elem => {
                let opt = document.createElement('option')

                opt.value = elem.id
                opt.text = elem.descripcion

                this.$selectGrado.insertAdjacentElement('beforeend', opt)
            })
        } catch (error) {
            console.error(error)
        }
    }

    async filterCompetencia() {
        try {
            const { data: { data } } = await axios.get(this.services.competencia)
            data.forEach(elem => {
                let opt = document.createElement('option')

                if (elem.id != 5){
                    opt.value = elem.id
                    opt.text = elem.descripcion
                    this.$selectCompetencia.insertAdjacentElement('beforeend', opt)
                }

            })
        } catch (error) {
            console.error(error)
        }
    }

    async filterEje(idCompetencia) {
        try {
            const { data: { data } } = await axios.get(this.services.ejeTematico(idCompetencia))

            this.$selectEje.innerHTML = '';
            this.$selectEje.innerHTML = `<option value="" selected>Eje temático</option>`

            data.forEach(elem => {
                let opt = document.createElement('option')

                opt.value = elem.id
                opt.text = elem.descripcion

                this.$selectEje.insertAdjacentElement('beforeend', opt)
            })
        } catch (error) {
            console.error(error)
        }
    }

    filtersListeners() {
        // Grados
        const onFilterGrado = (evt) => {
            const value = this.$selectGrado.options[this.$selectGrado.selectedIndex].value
            this.handleResuls()
        }

        this.$selectGrado.addEventListener('change', onFilterGrado)

        // Competencias
        const onFilterCompetencia = (evt) => {
            const value = this.$selectCompetencia.options[this.$selectCompetencia.selectedIndex].value
            this.filterEje(value)
            this.$selectEje.selectedIndex = 0;
            this.handleResuls()
        }

        this.$selectCompetencia.addEventListener('change', onFilterCompetencia)

        // Eje tematico
        const onFilterEje = (evt) => {
            // const value = this.$selectEje.options[this.$selectEje.selectedIndex].value
            this.handleResuls()
        }

        this.$selectEje.addEventListener('change', onFilterEje)
    }

    getCSRF() {
        return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    async update(evt) {
        const to = `${location.origin}${location.pathname.split('/').slice(0, -1).join('/')}?type_activity=1`

        const url = this.services.update(localStorage.getItem('_thr_id'), localStorage.getItem('_teacher_seccion_id'), localStorage.getItem('_current_id'), localStorage.getItem('_id'))

        try {
            const data = await axios.post(url, { '_token': this.getCSRF() })
            location.href = to
        } catch (err) {
            const data = err.response.data
            this.$view.header = data.message
            // si ocurre algun error al momento de actualizar la base los recursos
            if (data.message == 'CSRF token mismatch.') {
                alert("Tiempo agotado, intentelo nuevamente")
            } else {
                alert(data.message)
            }
        }
        location.href = to
    }

    otherListeners() {
        const _self = this
        this.$updateButton.addEventListener('click', _self.update.bind(_self))
    }

    firstRender() {
        this.filterGrade()
        this.filterCompetencia()
    }

    async init() {
        //
        await this.filtersListeners()
        this.otherListeners()

        //
        this.firstRender()
    }

    searchActive() {
        const pasos = document.querySelectorAll('[data-step]')
        pasos.forEach(paso => {
            if (paso.classList.contains("active")) {
                this.$pas = paso.dataset.step;
            }
        })
        return this.$pas;
    }
    activeListenerStep() {
        const pasos = document.querySelectorAll('[data-step]')
        pasos.forEach(paso => {

            paso.addEventListener("click", () => {

                if (paso.dataset.step == '1' && this.searchActive() == 2) {
                    this.$selectGrado.value = "";
                    this.$selectCompetencia.value = "";
                    this.$selectEje.value = "";
                    this.results([]);
                    this.activeStep(paso.dataset.step);
                }
                if (paso.dataset.step == '1' && this.searchActive() == 3) {
                    this.$view.step.classList.remove('d-none')
                    this.$view.step3.classList.add('d-none')
                    this.$selectGrado.value = "";
                    this.$selectCompetencia.value = "";
                    this.$selectEje.value = "";
                    this.results([]);
                    this.activeStep(paso.dataset.step);
                }
                if (paso.dataset.step == '2' && this.searchActive() == 3) {
                    this.$view.step.classList.remove('d-none')
                    this.$view.step3.classList.add('d-none')
                    this.activeStep(paso.dataset.step);
                }
            });
        })
    }

}

export default PageResurcesEdit
