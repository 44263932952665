import PageResurcesEdit from './app/teacher-resources-edit'
import PageResurcesShow from './app/teacher-resources-show'
import PageResurcesHome from './app/teacher-resources-home'

class App {
    constructor () {
        this.init()
    }

    init() {
        this.handlerPages()
    }

    handlerPages() {
        try {
            const page = document.querySelector('#_view').value
            if (!page) console.warn('No view')

            switch (page) {
                case 'teachers-edit':
                    new PageResurcesEdit()
                break

                case 'teachers-show':
                    new PageResurcesShow()
                break

                case 'teachers-home':
                    new PageResurcesHome()
                break
            }

        } catch (e) {
            //console.error(e)
        }
    }
}


(new App())
