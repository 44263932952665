class PageResurcesHome {
    constructor () {

        this.init()
    }

    goto() {
        window.goto = (url, id) => {
            // localStorage.setItem('_current_id', id)

            location.href = url
        }
    }

    async init () {
        this.goto()
    }
}

export default PageResurcesHome
