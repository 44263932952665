class PageResurcesShow {
    constructor() {

        this.init()
    }

    async init() {
        // localStorage.setItem('_current_id', document.getElementById('_theID').value)


    }
}

export default PageResurcesShow
